// 资讯列表
// sle
<template>
  <div class="body flex-row justify-center">
    <div class="main">
      <!-- <div class="pageTitle">资讯动态</div> -->
      <page-head title="资讯动态" />
      <a-spin :spinning="spinning">
        <a-list
          class="list"
          item-layout="vertical"
          size="large"
          :pagination="pagination"
          :data-source="list"
        >
          <template #renderItem="{ item }">
            <a-list-item key="item.title" class="item flex-row">
              <div class="left flex-column">
                <div class="title" @click="itemClick(item)">
                  {{ item.title }}
                </div>
                <div class="detail" v-html="item.titleDetails"></div>
              </div>
              <template #actions class="actions flex-columnReverse">
                <a-button @click="itemClick(item)">阅读详情</a-button>
              </template>
              <template #extra>
                <div class="extra flex-column align-end justify-around">
                  <span>{{ item.creationTimeS }}</span>
                  <img
                    class="img"
                    @click="itemClick(item)"
                    :src="
                      item.titlePicUrl == null || item.titlePicUrl == ''
                        ? 'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png'
                        : item.titlePicUrl
                    "
                  />
                </div>
              </template>
            </a-list-item>
          </template>
        </a-list>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'

export default defineComponent({
  components: {
    'page-head': PageHead,
  },
  data () {
    return {
      spinning: false,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: e => {
          this.paginationChange(e)
        },
      }, // 分页部分
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    // this.getInformationAnnSum()
    this.getInformationAnnList()
  },
  // 方法
  methods: {
    // 获取文章总数
    // getInformationAnnSum () {
    //   this.spinning = true
    //   api
    //     .get('/api/app/sys-information-ann/sys-information-ann-sum')
    //     .then(({ data }) => {
    //       this.spinning = false
    //       this.pagination.total = data
    //     })
    //     .catch(err => {
    //       this.spinning = false
    //       // this.$message.error('获取资讯列表失败')
    //       console.log(err)
    //     })
    // },
    // 获取文章列表
    getInformationAnnList () {
      this.spinning = true
      api
        .get('/api/app/sys-information-ann/paging-sys-information-ann-list', {
          params: {
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,

          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data.list
          this.list.forEach(element => {
            element.titleDetails = element.titleDetails.replace(/<img.*?(?:>|\/>)/gi, '').replace(/<figure.*?(?:>|\/>)/g, '')
          })
          this.pagination.total = data.pageCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取资讯列表失败')
          console.log(err)
        })
    },
    // 页码切换
    paginationChange (e) {
      this.pagination.current = e
      this.getInformationAnnList()
    },
    // 点击详情
    itemClick (e) {
      this.$router.push({
        path: '/WorkManagement/InformationAnnDetail',
        query: {
          id: e.id,
        },
      })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.body {
  width: 100%;
  .main {
    width: 82%;
    background: #fff;
    .pageTitle {
      width: 90%;
      font-size: 25px;
      margin: 25px 5%;
      font-family: PangMenZhengDao, PangMenZhengDao-Regular;
    }
    .list {
      width: 90%;
      margin: 0px 5%;
      margin-bottom: 40px;
      .item {
        height: 230px;
        margin: 15px 0px;
        padding: 25px;
        width: 100%;
        border: 1px solid #edebe9;
        border-radius: 4px;
        .extra {
          height: 100%;
          .img {
            max-height: 140px;
            max-width: 200px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .left {
          height: 80%;
          width: 100%;
          .title {
            height: 36px;
            font-size: 18px;
            line-height: 32px;
            font-weight: 700;

            cursor: pointer;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          .detail {
            margin-top: 10px;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            color: #8a8888;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
        .actions {
          height: 20%;
          width: 100%;
        }
      }
    }
  }
}
</style>
